/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */
import React from "react";
import FormFader from "../../Common/Fader/FormFader";
import wizardStyle from "../../wizardStyle";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useForm } from "../../../../provider/Form/FormProvider";
import { Box, Typography } from "@mui/material";
import FormLegend from "../FormLegend/FormLegend";
import Group from "../../../Wizard/Group";
import CheckIcon from "../../../../assets/icons/common/toggleable/IconCheck";
import CrossIcon from "../../../../assets/icons/common/toggleable/IconClose";
import { Link } from "react-router-dom";
import ButtonLoading from "../../../Button/ButtonLoading";
import { isRequired } from "../../../../services/validationRules";

type RecordingConsentFormProps = {
  isLoading: boolean;
};

export default function RecordingConsentForm({ isLoading = false }: RecordingConsentFormProps) {
  const { handleSubmit } = useForm();

  return (
    <Paper sx={wizardStyle.paper} elevation={4}>
      <FormFader>
        <Box component={"form"} onSubmit={handleSubmit}>
          <Grid container justifyContent={"center"} alignItems={"baseline"}>
            <Grid item xs={12}>
              <FormLegend>
                <strong>Einwilligung zur Aufzeichnung & Gesprächszusammenfassung</strong>
              </FormLegend>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ textAlign: "center", marginTop: "2rem" }}>
                Um Ihnen die bestmögliche Beratung zu bieten, bieten wir die optionale Aufzeichnung Ihres Gesprächs an. Nach dem Gespräch erhalten Sie eine
                strukturierte Zusammenfassung der besprochenen Inhalte, damit Sie jederzeit darauf zugreifen können.
              </Typography>
              <Typography sx={{ textAlign: "center", marginTop: "1rem" }}>
                Die Aufzeichnung erfolgt nur mit Ihrer ausdrücklichen Zustimmung und dient ausschließlich Ihrer Dokumentation. Die gespeicherten Daten werden
                vertraulich behandelt und nicht an unbefugte Dritte weitergegeben.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Group
                type="radio"
                name="recordingEnabled"
                question={""}
                options={[
                  {
                    labelText: "Ich stimme der Aufzeichnung meines Beratungsgesprächs zu und möchte im Anschluss eine Zusammenfassung erhalten.",
                    labelIcon: <CheckIcon />,
                    labelIconActive: <CheckIcon active />,
                    value: "yes",
                  },
                  {
                    labelText: "Ich lehne die Aufzeichnung meines Gesprächs ab.",
                    labelIcon: <CrossIcon />,
                    labelIconActive: <CrossIcon active />,
                    value: "no",
                  },
                ]}
                validators={[
                  {
                    validator: isRequired,
                    message: "Bitte entscheiden Sie ob sie einer Aufzeichnung zustimmen oder nicht.",
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ textAlign: "center" }}>
                <strong>Widerrufsrecht:</strong>
                <br />
                Sie können Ihre Zustimmung jederzeit widerrufen – auch während des Gesprächs. In diesem Fall wird entweder keine Aufzeichnung erstellt oder eine
                bereits begonnene Aufzeichnung sofort gestoppt und unwiderruflich gelöscht.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ textAlign: "center", marginTop: "2rem" }}>
                <strong>Datenschutz & DSGVO-Hinweise:</strong>
                <ul style={{ listStylePosition: "inside" }}>
                  <li>
                    Die Aufzeichnung erfolgt auf Grundlage Ihrer <strong>freiwilligen Einwilligung</strong> gemäß <strong>Art. 6 Abs. 1 lit. a DSGVO</strong>.
                  </li>
                  <li>
                    Ihre Daten werden <strong>nicht an unbefugte Dritte weitergegeben</strong>.
                  </li>
                  <li>
                    Die Verarbeitung erfolgt über unsere <strong>vertrauenswürdigen technischen Dienstleister</strong>, mit denen wir DSGVO-konforme Verträge
                    abgeschlossen haben.
                  </li>
                  <li>
                    Weitere Informationen zu Ihren Datenschutzrechten finden Sie in unserer{" "}
                    <Link target={"_blank"} to={"/datenschutz"}>
                      Datenschutzerklärung
                    </Link>
                    .
                  </li>
                </ul>
              </Typography>
            </Grid>
            <Grid item sx={{ paddingTop: "1rem", paddingBottom: "2rem" }} xs={12} sm={6}>
              <ButtonLoading fullWidth variant={"contained"} color={"primary"} type={"submit"} disabled={isLoading} isLoading={isLoading}>
                Rückmeldung geben
              </ButtonLoading>
            </Grid>
          </Grid>
        </Box>
      </FormFader>
    </Paper>
  );
}
