/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import Layout from "../../Layouts/Layout";
import React, { useState } from "react";

import { Helmet } from "react-helmet-async";
import { FormProvider } from "../../../provider/Form/FormProvider";
import Container from "../../../components/Container/Container";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import { useParams } from "react-router-dom";
import RecordingConsentForm from "../../../components/Form/Common/Appointment/RecordingConsentForm";
import { apiPut } from "../../../services/apiClient";
import RecordingConsentSuccess from "../../../components/Form/Common/Appointment/RecordingConsentSuccess";
import useProductParams from "../../../hooks/useProductParams";
import { getProductData, getProductNameBy } from "../../../services/productService";
import { useQuery } from "@tanstack/react-query";
import { fetchResource, queryKeys } from "../../../services/reactQuery/reactQueryService";
import LegalbirdLoader from "../../../components/ContentLoader/LegalbirdLoader";

export default function RecordingConsentPage() {
  const [isLoading, setIsLoadingInternal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [externalErrors] = useState({});
  const { productName, productId } = useProductParams();
  const productClassName = getProductNameBy("urlPartShort", productName);
  const productApiUri = getProductData(productClassName, "apiUri");
  const { data: product, isLoading: isLoadingProduct } = useQuery(queryKeys.item(productApiUri, productId), () => fetchResource(productApiUri, productId));

  const getCalendlyEventId = () => {
    const { calendlyEventId } = useParams();

    return calendlyEventId ? parseInt(calendlyEventId) : 0;
  };

  const calendlyEventId = getCalendlyEventId();

  const initialValues = {
    recordingEnabled: null,
  };

  const handleSubmit = async ({ values }: { values: AbstractCase }) => {
    setIsLoadingInternal(true);

    const result = await apiPut(`/update_recording_consent/${calendlyEventId}`, {
      recordingEnabled: values["recordingEnabled"] ?? null,
    });

    if (result === "Ok") {
      setIsSuccess(true);
    }

    setIsLoadingInternal(false);
  };

  if (isLoadingProduct) {
    return <LegalbirdLoader />;
  }

  return (
    <Layout backgroundType={"serviceworld"}>
      <Helmet>
        <title>Einwilligung zur Aufzeichnung & Gesprächszusammenfassung | Legalbird</title>
      </Helmet>
      <Container style={{ paddingTop: "4rem" }}>
        <FormProvider initialValues={initialValues} submitAction={handleSubmit} externalErrors={externalErrors}>
          {!isSuccess && <RecordingConsentForm isLoading={isLoading} />}
          {isSuccess && <RecordingConsentSuccess product={product} />}
        </FormProvider>
      </Container>
    </Layout>
  );
}
